<template>
    <div v-if="displaySearch" style="" v-bind:class="{ 'mobile_container' : mobile && mobileSearch, 'desktop_container': !mobile}">
        <!-- mobile search -->
        <v-text-field
            placeholder="Išči..."
            color="white"
            append-icon="mdi-close"
            @click:append="hideMobileSearch()"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            v-show="mobileSearch"
            v-on:keyup.esc="hideMobileSearch()"
            class="hidden-md-and-up pt-10"
            ref="search"
            style="width:100%;"
        ></v-text-field>

        <!-- desktop search -->
        <v-text-field
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            class="hidden-sm-and-down pt-6"
            label="Išči..."
            v-model="search"
            clearable
            v-on:keyup.esc="hideMobileSearch()"
        ></v-text-field>&nbsp;&nbsp;
        <v-btn icon class="hidden-md-and-up" @click="btnSearch()" v-show="!mobileSearch">
            <v-icon>mdi-magnify</v-icon>
        </v-btn>
    </div>
</template>

<script>

export default {
    name: 'app-toolbar-search',
    data: () => ({
    }),

    computed: {
        displaySearch() {
            let routeName = this.$store.getters.currentRouteName
            if(routeName == 'users_list' || routeName == 'dashboard' || routeName == 'parking_barriers_archive') {
                return true
            }

            return false
        },

        listMarginLeft() {
            if(this.$vuetify.breakpoint.lgAndUp) {
                return true;
            }

            return false;
        },

        mobile() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return true;
            }

            this.hideMobileSearch()
            return false;
        },

        search: {
            get() {
                return this.$store.getters.search
            },

            set(value) {
                this.$store.commit('SET_SEARCH', value)
            }
        },

        toolbarTitle() {
            return this.$store.getters.toolbarTitle
        },

        mobileSearch: {
            get() {
                return this.$store.getters.mobileSearch
            },

            set(value) {
                this.$store.commit('SET_MOBILE_SEARCH', value)                
            }

        }
    },

    watch: {

    },

    methods: {
        hideMobileSearch() {
            this.$store.commit('SET_SEARCH', '');
            this.$store.commit('SET_SEARCH_FIELD_STATE', false);
            this.$store.commit('SET_MOBILE_SEARCH', false);
            //this.mobileSearch = false;
        },

        btnSearch() {
            //this.mobileSearch = !this.mobileSearch
            this.$store.commit('SET_MOBILE_SEARCH', !this.$store.getters.mobileSearch);
            this.$store.commit('SET_SEARCH_FIELD_STATE', true);
            setTimeout(() =>{
                this.$refs.search.focus();
            }, 500)
        }
    },

    created() {

    },

    mounted() {

    },

    destroyed() {

    }
}

</script>

<style scoped>
    .desktop_container {
        width: 350px;
        margin-right:15px;
    }

    .mobile_container {
        width: 100%;
    }
</style>